import Cookies from 'js-cookie';
import { FC, ReactNode, useEffect, useState } from 'react';

import { NotSignedIn } from '@components/Account';
import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Loading } from '@components/Alerts/Loading';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { Container } from '@components/Container';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useIsTabActive } from '@hooks/useIsTabActive';
import { useLoadingState } from '@hooks/useLoadingState';
import { COOKIES } from '@lib/constants';

type Props = {
  children: ReactNode;
};

const AccountLayout: FC<Props> = ({ children }) => {
  const { customer, isLoading } = useCustomer();
  const tabInFocus = useIsTabActive();
  const { isLoaded, stateLoaded } = useLoadingState(1);
  const [loggedIn, setLoggedIn] = useState(false);
  const [customerToken, setCustomerToken] = useState<string | null>(null);

  // Handle logout between tabs
  // Read the customer cookie into state whenever the tab becomes active
  useEffect(() => {
    if (tabInFocus) {
      const cookie = Cookies.get(COOKIES.customer);

      if (cookie && customerToken === null) {
        setCustomerToken(cookie); // Set the cookie in state
      } else if (cookie && cookie !== customerToken) {
        window.location.reload(); // customer has changed
      }
    }
  }, [tabInFocus]);

  // Customer changes, update login status
  useEffect(() => {
    let mounted = true;

    if (mounted && customer !== undefined) {
      setLoggedIn(customer !== null);
    }

    if (mounted && !isLoading && !isLoaded()) {
      stateLoaded();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [customer, stateLoaded, isLoading, isLoaded]);

  return (
    <>
      <Header />
      {!isLoaded() ? (
        <Loading />
      ) : (
        <>
          {loggedIn || process.env.NEXT_PUBLIC_AUTH_REQUIRED === 'false' ? (
            <>
              <BulkErrorBoundary location="Account Header">
                <Container id="account-breadcrumbs">
                  <Breadcrumbs
                    crumbs={[
                      { title: 'home', path: '/' },
                      { title: 'My Account', path: '/account' },
                    ]}
                  />
                </Container>
                <Container
                  as="main"
                  id="main-content"
                  className="grid-cols-[1fr_min(theme(width.narrowPage),_calc(100%_-_16px))_1fr] [&_h1]:text-center"
                >
                  {children}
                </Container>
              </BulkErrorBoundary>
            </>
          ) : (
            <NotSignedIn />
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export { AccountLayout };
