/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable dot-notation */
import React, {
  Dispatch,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useSWRConfig } from 'swr';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { SuccessAlert } from '@components/Alerts/SuccessAlert';
import { HorizontalDivider } from '@components/HorizontalDivider/HorizontalDivider';
import { Modal } from '@components/Modal/Modal';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useAddresses } from '@hooks/address/useAddresses';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useCsrfCookie } from '@hooks/useCsrfCookie';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { SWR_KEYS } from '@lib/constants';
import { UpdateAccountAPIResponse } from 'pages/api/customer/update-account';

import { AccountSettingsFormFields } from '../../interfaces/IForms';
import { Button, ButtonType } from '../Button';
import { Input } from '../FormComponents';

function CodeVerification({
  show,
  setShow,
  onSubmit,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
}): ReactElement {
  const close = () => setShow(false);
  const rhfMethods = useFormContext();

  return (
    <Modal
      show={show}
      setShow={setShow}
      className="max-h-[85vh] w-[90vw] max-w-[450px] rounded-md p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-grey-darkest"
    >
      <section className="flex flex-col items-center justify-center">
        <IoMdInformationCircleOutline size="80px" color="hsl(190, 98%, 37%)" />
        <h2 className="mt-4">Verification Required</h2>
        <div className="my-4 flex items-center justify-evenly gap-4">
          <div className="flex flex-col gap-3">
            <Paragraph>
              We've sent you an email to confirm you are the owner of this email
              address. Please enter the verification code in the field below to
              update your password
            </Paragraph>

            <Input
              label="Verification Code"
              name="verificationCode"
              type="number"
              required
              onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                if (e.key === 'Enter') {
                  onSubmit();
                  close();
                }
              }}
            />
          </div>
        </div>
        <Button
          type={ButtonType.button}
          onClick={() => {
            onSubmit();
            close();
          }}
          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Enter') {
              onSubmit();
              close();
            }
          }}
          disabled={!rhfMethods.formState.isValid}
        >
          Confirm
        </Button>
      </section>
    </Modal>
  );
}

export function AccountSettingsForm(): ReactElement {
  const { addresses } = useAddresses(); // get country for phone number
  const { customer } = useCustomer();
  const { mutate } = useSWRConfig();
  const csrfToken = useCsrfCookie();

  const [country, setCountry] = useState('AU');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState<ReactNode>();
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledPw, setDisabledPw] = useState(false);
  const [alertVerification, setAlertVerification] = useState(false);

  const handleSubmit = async (e: AccountSettingsFormFields) => {
    setDisabled(true);

    const updateAccountResponse = await fetch('/api/customer/update-account', {
      method: 'POST',
      body: JSON.stringify({ values: e, customerId: customer?.entityId }),
    }).then((res) => res.json() as Promise<UpdateAccountAPIResponse>);

    if (!isErrorResponse(updateAccountResponse)) {
      mutate(SWR_KEYS.customer);
      setError(false);
      setSuccess(true);
      setDisabled(false);
      rhfMethods.reset();
    } else {
      setError(true);
      setSuccess(false);
      setDisabled(false);
      setErrorText(
        <Paragraph>{updateAccountResponse.error.message.join('. ')}</Paragraph>
      );
      rhfMethods.reset();
    }
  };

  const handlePasswordSubmit = async (e: AccountSettingsFormFields) => {
    setDisabledPw(true);

    // If changing password, prompt  for email confirmation
    if (e.password && !e.verificationCode) {
      const sentEmailResponse = await fetch('/api/verify', {
        method: 'POST',
        body: JSON.stringify({ email: e.email }),
      });
      if (!sentEmailResponse.ok) {
        setError(true);
        setSuccess(false);
        setErrorText(
          <Paragraph>
            There was an issue sending an email to confirm your email address.
          </Paragraph>
        );
        setDisabledPw(false);
        return;
      } else {
        setAlertVerification(true);
        setDisabledPw(false);
        return;
      }
    }

    const updatePasswordResponse = await fetch('/api/customer/update-account', {
      method: 'POST',
      body: JSON.stringify({ values: e, customerId: customer?.entityId }),
    }).then((res) => res.json() as Promise<UpdateAccountAPIResponse>);

    if (!isErrorResponse(updatePasswordResponse)) {
      setError(false);
      setSuccess(true);
      setDisabledPw(false);
      rhfMethodsPw.reset();
    } else {
      setError(true);
      setSuccess(false);
      setDisabledPw(false);
      setErrorText(
        <Paragraph>{updatePasswordResponse.error.message.join('. ')}</Paragraph>
      );
      rhfMethodsPw.reset();
    }
  };

  const rhfMethods = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      company: customer?.company,
      phone: customer?.phone,
      email: customer?.email,
      country: country,
      csrfToken,
    },
  });

  const rhfMethodsPw = useForm<any>({
    mode: 'onTouched',
    defaultValues: {
      email: customer?.email,
      password: '',
      confirm: '',
      current: '',
      csrfToken,
      verificationCode: '',
    },
  });

  useEffect(() => {
    rhfMethods.reset({
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      company: customer?.company,
      phone: customer?.phone,
      email: customer?.email,
      country: country,
      csrfToken,
    });
  }, [customer, country, csrfToken, rhfMethods]);

  useEffect(() => {
    rhfMethodsPw.reset({
      email: customer?.email,
      password: '',
      confirm: '',
      current: '',
      csrfToken,
      verificationCode: '',
    });
  }, [customer, csrfToken, rhfMethodsPw]);

  useEffect(() => {
    if (addresses) {
      const countries = addresses.reduce(
        (acc, cur) => {
          acc[cur.country_code] += 1;
          return acc;
        },
        { AU: 0, NZ: 0 }
      );

      if (countries.AU < countries.NZ) {
        setCountry('NZ');
      } else {
        setCountry('AU');
      }
    }
  }, [addresses]);

  return (
    <div>
      <SuccessAlert
        title="Updated!"
        message="Those new details have been saved"
        show={success}
        setShow={setSuccess}
      />
      <ErrorAlert show={error} setShow={setError}>
        {errorText}
      </ErrorAlert>
      <FormProvider {...rhfMethods}>
        <form
          className="flex flex-col gap-3"
          onSubmit={rhfMethods.handleSubmit(handleSubmit)}
        >
          <h4 className="col-start-1 col-end-[span_2]">Your Details</h4>
          <Input label="First Name" name="firstName" type="text" required />
          <Input label="Last Name" name="lastName" type="text" required />
          <Input label="Company" name="company" type="text" />
          <Input
            label="Phone Number"
            name="phone"
            type="text"
            required
            placeholder="Include area code for landline eg. 03 6266 4725"
            onChange={async (e) => {
              const { value } = e.currentTarget;
              // Dynamically load libphonenumber-js
              const {
                isValidPhoneNumber,
                parsePhoneNumberWithError,
                ParseError,
              } = await import('libphonenumber-js/max');

              const formatPhoneNumber = (
                value: string,
                countryCode: string
              ): string => {
                try {
                  const phoneNumber = parsePhoneNumberWithError(
                    value,
                    countryCode === 'NZ' ? 'NZ' : 'AU'
                  );
                  if (phoneNumber) {
                    return phoneNumber.format('E.164');
                  }
                } catch (error) {
                  if (error instanceof ParseError) {
                    // Not a phone number, non-existent country, etc.
                    console.error(error.message);
                  }
                }
                return value;
              };

              if (
                !isValidPhoneNumber(
                  value,
                  rhfMethods.getValues('country') === 'NZ' ? 'NZ' : 'AU'
                )
              ) {
                rhfMethods.setError('phone', {
                  type: 'custom',
                  message: 'Phone number must be valid format',
                });
              }
              rhfMethods.setValue(
                'phone',
                formatPhoneNumber(
                  value,
                  rhfMethods.getValues('country') === 'NZ' ? 'NZ' : 'AU'
                ),
                { shouldValidate: false }
              );
            }}
          />
          <Input
            label="Email Address"
            name="email"
            type="text"
            required
            readOnly
            description={
              <div className="italic">
                To change your email address,{' '}
                <TextLink href="/contact-us">contact us</TextLink>.
              </div>
            }
          />
          <div className="formbutton flex justify-center">
            <Button type={ButtonType.submit} disabled={disabled}>
              Update your details
            </Button>
          </div>
        </form>
      </FormProvider>
      <HorizontalDivider />
      <FormProvider {...rhfMethodsPw}>
        <form
          className="flex flex-col gap-3"
          onSubmit={rhfMethodsPw.handleSubmit(handlePasswordSubmit)}
        >
          <CodeVerification
            show={alertVerification}
            setShow={setAlertVerification}
            onSubmit={rhfMethodsPw.handleSubmit(handlePasswordSubmit)}
          />
          <h4 className="col-start-1 col-end-[span_2]">Change Password</h4>
          <Input
            label="New Password"
            name="password"
            type="password"
            description="Passwords must be 12+ characters, with an uppercase, lowercase, number, and optionally @$!%*#?&()"
          />
          <Input
            label="Confirm New Password"
            name="confirm"
            type="password"
            validate={(pwdValue: string) => {
              if (pwdValue !== rhfMethodsPw.getValues('password'))
                return 'Passwords do not match.';
            }}
          />
          <Input
            label="Current Password"
            name="current"
            type="password"
            required
          />

          <div className="formbutton flex justify-center">
            <Button type={ButtonType.submit} disabled={disabledPw}>
              Update your password
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
